import React from "react";
import { Router } from "@reach/router";
import "./App.css";
import LaunchingSoonLandingPage from "./pages/LaunchingSoonLandingPage";
import TnCPage from "./pages/TncPage";
import PrivacyPage from "./pages/PrivacyPage";
import NotFound from "./pages/NotFound";
import DisclaimerPage from "./pages/DisclaimerPage";
import PricingPage from "./pages/PricingPage";
import VoicesTnCPage from "./pages/fav-voices/TncPage";
import VoicesPrivacyPage from "./pages/fav-voices/PrivacyPage";
import VoicesDisclaimerPage from "./pages/fav-voices/DisclaimerPage";
import VoicesPricingPage from "./pages/fav-voices/PricingPage";

const App = () => {
  return (
    <>
      <Router>
        <LaunchingSoonLandingPage path="/" />
        <TnCPage path="/terms" />
        <PrivacyPage path="/privacy-policy" />
        <DisclaimerPage path="/disclaimer" />
        <PricingPage path="/pricing-info" />
        <VoicesTnCPage path="/voices/terms" />
        <VoicesPrivacyPage path="/voices/privacy-policy" />
        <VoicesDisclaimerPage path="/voices/disclaimer" />
        <VoicesPricingPage path="/voices/pricing-info" />
        <NotFound default />
      </Router>
    </>
  );
};

export default App;
