import React from "react";
import { Link } from "@reach/router";

const TnCPage = () => {
  return (
    <div className="relative py-4 font-grotesk bg-light-gray overflow-hidden min-h-screen">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className=" font-grotesk relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <div className="flex items-center justify-center flex-shrink-0">
            <Link to="/">
              <img
                className="h-14 md:h-16 xl:h-24 2xl:h-28 w-auto p-2 rounded-md"
                src="/assets/images/icons/udruReaderLogo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="mt-2 block text-2xl md:text-3xl xl:text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900">
            Terms And Conditions
          </div>
        </div>
        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium uppercase text-black">
            PLEASE READ THESE LICENCE TERMS CAREFULLY
          </h2>
          <p className="text-justify text-sm font-medium leading-none text-black  ">
            BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THESE TERMS
            WHICH WILL BIND YOU
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Who we are and what this agreement does:
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We URDU READER of 86-90 PAUL STREET, LONDON, EC2A 4NE, UNITED
            KINGDOM license you to use:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="ml-10 mr-5 list-disc">
            <li className="text-sm font-medium text-black  ">
              URDU READER mobile application software, the data supplied with
              the software, (App) and any updates or supplements to it.
            </li>
            <li className="text-sm font-medium text-black  ">
              The service you connect to via the App and the content we provide
              to you through it (Service)
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            As permitted in these term:
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We will start charging at the rate of $15 per annum for this App
            from 30 September 2022 onwards. Thereafter, the annual fee for this
            App will increase over time. We also reserve the right to send the
            updated versions of this App to your mobile phone as and when we
            release new versions of the App.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Your privacy
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We only use any personal data we collect through your use of the App
            and the Services in the ways set out in our privacy policy{" "}
            <Link className="underline " to="/privacy-policy">
              www.urdureaderapp.com/privacy-policy
            </Link>{" "}
            unless you are using a specific service to which a different privacy
            policy applies, as set out below.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            Please be aware that internet transmissions are never completely
            private or secure and that any message or information you send using
            the App or any Service may be read or intercepted by others, even if
            there is a special notice that a particular transmission is
            encrypted.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            app store & Google Play Store terms also apply
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            The ways in which you can use the App and Documentation may also be
            controlled by the APP STORE’s rules and policies and APP STORE's
            rules and policies will apply instead of these terms where there are
            differences between the two.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Operating system requirements
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            This app requires an iPhone, iPad or Android device with an iOS or
            Android operating system, iOS version 11 and above and Android
            version 8 and above.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Support for the App and how to tell us about problems
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">Support.</span>{" "}
            If you want to learn more about the App or the Service or have any
            problems using them, please take a look at our support resources at{" "}
            <Link className="underline" to="/">
              www.urdureaderapp.com
            </Link>{" "}
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              Contacting us (including with complaints).
            </span>{" "}
            If you think the App or the Services are faulty or misdescribed or
            wish to contact us for any other reason please email our customer
            service team at{" "}
            <a className="underline" href="mailto:contact@urdureaderapp.com">
              contact@urdureaderapp.com
            </a>{" "}
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              How we will communicate with you.
            </span>{" "}
            If we have to contact you, we will do so by email or SMS using the
            contact details you have provided to us.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            How you may use the App, including how many devices you may use it
            on
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            In return for your agreeing to comply with these terms you may:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black  ">
              download or stream a copy of the App onto any number of iPhone or
              Android devices with an iOS or Android operating system iOS
              version 11 and above and Android version 8 and above and view, use
              and display the App and the Service on such devices for your
              personal purposes only.
            </li>
            <li className="text-sm font-normal text-black  ">
              use any Documentation to support your permitted use of the App and
              the Service.
            </li>
            <li className="text-sm font-normal text-black  ">
              the User cannot make any copies of the Application and use it on
              any other devices in an unauthorised way; and
            </li>
            <li className="text-sm font-normal text-black  ">
              receive and use any free supplementary software code or update of
              the App incorporating "patches" and corrections of errors as we
              may provide to you.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            You must be 12 years or over to accept these terms and buy the app
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            You must be 12 years or over to accept these terms and buy the App.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            You may not transfer the App to someone else
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We are giving you personally the right to use the App and the
            Service as set out above. Whilst you may have sharing rights as set
            out above, you may not otherwise transfer the App or the Service to
            someone else, whether for money, for anything else or for free. If
            you sell any device on which the App is installed, you must remove
            the App from it.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Changes to these terms
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We may need to change these terms to reflect changes in law or best
            practice or to deal with additional features which we introduce.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We will give you at least 10 days’ notice of any change by sending
            you an SMS and/or mobile notification with details of the change or
            notifying you of a change when you next start the App.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            If you do not accept the notified changes you may continue to use
            the App and the Service in accordance with the existing terms but
            certain new features may not be available to you or will not be
            permitted to continue to use the App and the Service and you may
            apply to the Appstore for a refund, which will reflect the period
            the App and the Service have been available to you prior to
            cancellation.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Update to the App and changes to the Service
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            From time to time we may automatically update the App and change the
            Service to improve performance, enhance functionality, reflect
            changes to the operating system or address security issues.
            Alternatively, we may ask you to update the App for these reasons.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            If you choose not to install such updates or if you opt out of
            automatic updates you may not be able to continue using the App and
            the Services.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            The App will always work with the current or previous version of the
            operating system (as it may be updated from time to time) and match
            the description of it provided to you when you bought it
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            If someone else owns the phone or device you are using
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            If you download or stream the App onto any phone or other device not
            owned by you, you must have the owner's permission to do so. You
            will be responsible for complying with these terms, whether or not
            you own the phone or other device.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            We may collect technical data about your device
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            By using the App or any of the Services, you agree to us collecting
            and using technical information about the devices you use the App on
            and related software, hardware and peripherals to improve our
            products and to provide any Services to you.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            We are not responsible for other websites you link to
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            The App or any Service may contain links to other independent
            websites which are not provided by us. Such independent sites are
            not under our control, and we are not responsible for and have not
            checked and approved their content or their privacy policies (if
            any).
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            You will need to make your own independent judgement about whether
            to use any such independent sites, including whether to buy any
            products or services offered by them.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Licence restrictions
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            You agree that you will:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black  ">
              except in the course of permitted sharing, not rent, lease,
              sub-license, loan, provide, or otherwise make available, the App
              or the Services in any form, in whole or in part to any person
              without prior written consent from us;
            </li>
            <li className="text-sm font-normal text-black  ">
              not copy the App, Documentation or Services, except as part of the
              normal use of the App or where it is necessary for the purpose of
              back-up or operational security;
            </li>
            <li className="text-sm font-normal text-black  ">
              not translate, merge, adapt, vary, alter or modify, the whole or
              any part of the App, Documentation or Services nor permit the App
              or the Services or any part of them to be combined with, or become
              incorporated in, any other programs, except as necessary to use
              the App and the Services on devices as permitted in these terms;
            </li>
            <li className="text-sm font-normal text-black  ">
              not disassemble, de-compile, reverse engineer or create derivative
              works based on the whole or any part of the App or the Services
              nor attempt to do any such things, except to the extent that (by
              virtue of sections 50B and 296A of the Copyright, Designs and
              Patents Act 1988) such actions cannot be prohibited because they
              are necessary to decompile the App to obtain the information
              necessary to create an independent program that can be operated
              with the App or with another program{" "}
              <span className="font-medium opacity-100 text-black">
                (Permitted Objective)
              </span>
              , and provided that the information obtained by you during such
              activities:
            </li>
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black  ">
                is not disclosed or communicated without the Licensor's prior
                written consent to any third party to whom it is not necessary
                to disclose or communicate it in order to achieve the Permitted
                Objective; and
              </li>
              <li className="text-sm font-normal text-black  ">
                is not used to create any software that is substantially similar
                in its expression to the App;
              </li>
              <li className="text-sm font-normal text-black  ">
                is kept secure; and
              </li>
              <li className="text-sm font-normal text-black  ">
                is used only for the Permitted Objective;
              </li>
            </ul>
            <li className="text-sm font-normal text-black  ">
              comply with all applicable technology control or export laws and
              regulations that apply to the technology used or supported by the
              App or any Service.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Acceptable use restrictions
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            You must:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black  ">
              not use the App or any Service in any unlawful manner, for any
              unlawful purpose, or in any manner inconsistent with these terms,
              or act fraudulently or maliciously, for example, by hacking into
              or inserting malicious code, such as viruses, or harmful data,
              into the App, any Service or any operating system;
            </li>
            <li className="text-sm font-normal text-black  ">
              not infringe our intellectual property rights or those of any
              third party in relation to your use of the App or any Service,
              including by the submission of any material, (to the extent that
              such use is not licensed by these terms);
            </li>
            <li className="text-sm font-normal text-black  ">
              not transmit any material that is defamatory, offensive or
              otherwise objectionable in relation to your use of the App or any
              Service;
            </li>
            <li className="text-sm font-normal text-black  ">
              not use the App or any Service in a way that could damage,
              disable, overburden, impair or compromise our systems or security
              or interfere with other users; and
            </li>
            <li className="text-sm font-normal text-black  ">
              not collect or harvest any information or data from any Service or
              our systems or attempt to decipher any transmissions to or from
              the servers running any Service.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Intellectual property rights
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            All intellectual property rights in the App, the Documentation and
            the Services throughout the world belong to us (or our licensors)
            and the rights in the App and the Services are licensed (not sold)
            to you. You have no intellectual property rights in, or to, the App,
            the Documentation or the Services other than the right to use them
            in accordance with these terms.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Our responsibility for loss or damage suffered by you
          </h2>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              We are responsible to you for foreseeable loss and damage caused
              by us.
            </span>{" "}
            If we fail to comply with these terms, we are responsible for loss
            or damage you suffer that is a foreseeable result of our breaking
            these terms or our failing to use reasonable care and skill, but we
            are not responsible for any loss or damage that is not foreseeable.
            Loss or damage is foreseeable if either it is obvious that it will
            happen or if, at the time you accepted these terms, both we and you
            knew it might happen. We are not responsible if any of your devices
            are hacked into or interfered with by other parties.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              We do not exclude or limit in any way our liability to you where
              it would be unlawful to do so.
            </span>{" "}
            This includes liability for death or personal injury caused by our
            negligence or the negligence of our employees, agents or
            subcontractors or for fraud or fraudulent misrepresentation.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              When we are liable for damage to your property.
            </span>{" "}
            If defective digital content that we have supplied damages a device
            or digital content belonging to you, we will either repair the
            damage or pay you compensation. However, we will not be liable for
            damage that you could have avoided by following our advice to apply
            an update offered to you free of charge or for damage that was
            caused by you failing to correctly follow installation instructions
            or to have in place the minimum system requirements advised by us.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              We are not liable for business losses.
            </span>{" "}
            The App is for domestic and private use. If you use the App for any
            commercial, business or resale purpose we will have no liability to
            you for any loss of profit, loss of business, business interruption,
            or loss of business opportunity.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              Limitations to the App and the Services.
            </span>{" "}
            The App and the Services are provided for general information and
            entertainment purposes only. They do not offer advice on which you
            should rely. You must obtain professional or specialist advice
            before taking, or refraining from, any action on the basis of
            information obtained from the App or the Service. Although we make
            reasonable efforts to update the information provided by the App and
            the Service, we make no representations, warranties or guarantees,
            whether express or implied, that such information is accurate,
            complete or up to date.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              Please back-up content and data used with the App.
            </span>{" "}
            We recommend that you back up any content and data used in
            connection with the App, to protect yourself in case of problems
            with the App or the Service.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              Check that the App and the Services are suitable for you.
            </span>{" "}
            The App and the Services have not been developed to meet your
            individual requirements. Please check that the facilities and
            functions of the App and the Services (as described on the Appstore
            site and in the Documentation) meet your requirements.
          </p>

          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            <span className="font-medium opacity-100 text-black">
              We are not responsible for events outside our control.
            </span>{" "}
            If our provision of the Services or support for the App or the
            Services is delayed by an event outside our control, then we will
            contact you as soon as possible to let you know and we will take
            steps to minimise the effect of the delay. Provided we do this we
            will not be liable for delays caused by the event but if there is a
            risk of substantial delay you may contact us to end your contract
            with us and receive a refund for any Services you have paid for but
            not received.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            We may end your rights to use the App and the Services if you break
            these terms
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We may end your rights to use the App and Services at any time by
            contacting you if you have broken these terms in a serious way. If
            what you have done can be put right, we will give you a reasonable
            opportunity to do so.
          </p>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            If we end your rights to use the App and Services:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black  ">
              You must stop all activities authorised by these terms, including
              your use of the App and any Services.
            </li>
            <li className="text-sm font-normal text-black  ">
              You must delete or remove the App from all devices in your
              possession and immediately destroy all copies of the App which you
              have and confirm to us that you have done this.
            </li>
            <li className="text-sm font-normal text-black  ">
              We may remotely access your devices and remove the App from them
              and cease providing you with access to the Services.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            We may transfer this agreement to someone else
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            We may transfer our rights and obligations under these terms to
            another organisation. We will always tell you in writing if this
            happens and we will ensure that the transfer will not affect your
            rights under the contract.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            You need our consent to transfer your rights to someone else
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            You may only transfer your rights or your obligations under these
            terms to another person if we agree in writing.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            No rights for third parties
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            This agreement does not give rise to any rights under the Contracts
            (Rights of Third Parties) Act 1999 to enforce any term of this
            agreement.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            If a court finds part of this contract illegal, the rest will
            continue in force
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            Each of the paragraphs of these terms operates separately. If any
            court or relevant authority decides that any of them are unlawful,
            the remaining paragraphs will remain in full force and effect.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Even if we delay in enforcing this contract, we can still enforce it
            later
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            Even if we delay in enforcing this contract, we can still enforce it
            later. If we do not insist immediately that you do anything you are
            required to do under these terms, or if we delay in taking steps
            against you in respect of your breaking this contract, that will not
            mean that you do not have to do those things and it will not prevent
            us taking steps against you at a later date.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Which laws apply to this contract and where you may bring legal
            proceedings
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            These terms are governed by English law and you can bring legal
            proceedings in respect of the products in the English courts. The
            courts of England have exclusive jurisdiction to deal with any
            disputes arising out of this agreement
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium uppercase text-black">
            Alternative dispute resolution
          </h2>
          <p className="text-justify text-sm font-normal leading-tight  text-black  ">
            Alternative dispute resolution is a process where an independent
            body considers the facts of a dispute and seeks to resolve it,
            without you having to go to court. If you are not happy with how we
            have handled any complaint, you may want to contact the alternative
            dispute resolution provider we use. You can submit a complaint to
            the London Mediation Service via their website at{" "}
            <a className="underline" href="https://www.tlms.org.uk">
              www.tlms.org.uk
            </a>
            . London Mediation Service will not charge you for making a
            complaint and if you are not satisfied with the outcome you can
            still bring legal proceedings. In addition, please note that
            disputes may be submitted for online resolution to the European
            Commission Online Dispute Resolution platform.
          </p>
        </div>
      </div>
    </div>
  );
};
export default TnCPage;
