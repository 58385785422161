import React from "react";
import { Link } from "@reach/router";

const PricingPage = () => {
  return (
    <div className="relative py-4 font-grotesk bg-light-gray overflow-hidden min-h-screen">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className=" font-grotesk relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <div className="flex items-center justify-center flex-shrink-0">
            <Link to="/">
              <img
                className="h-16 md:h-18 xl:h-28 2xl:h-32 w-auto  p-2 rounded-md"
                src="/assets/images/icons/favouriteVoicesLogo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="mt-2 block text-2xl md:text-3xl xl:text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900">
            Charging for the App
          </div>
        </div>
        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-4 md:pt-6 xl:pt-10">
          <p className="text-justify text-lg md:text-xl xl:text-xl font-normal leading-tight  text-black">
            After the initial testing phase is over, it is our intention to
            charge a small annual fee for the App. The App will be free to
            download. The first 5 documents, sent for audio, will be free.
            Thereafter, will be charging users $15 per year for the App. This
            amount will increase over time.
          </p>
          <p className="text-justify text-lg md:text-xl xl:text-xl font-normal leading-tight  text-black">
            The reason why we have to charge for the App is because, having an
            App is like having a garden: we have to continuously maintain it. It
            takes a lot of technical resource to keep a complicated App such as
            this updated.
          </p>
          <p className="text-justify text-lg md:text-xl xl:text-xl font-normal leading-tight  text-black">
            We thank you for your ongoing support for this App.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PricingPage;
