import React from "react";
import { Link } from "@reach/router";

const PrivacyPage = () => {
  return (
    <div className="relative py-4 font-grotesk bg-light-gray overflow-hidden min-h-screen">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className="font-grotesk relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg mx-auto">
          <div className="flex items-center justify-center flex-shrink-0">
            <Link to="/">
              <img
                className="h-16 md:h-18 xl:h-28 2xl:h-32 w-auto  p-2 rounded-md"
                src="/assets/images/icons/favouriteVoicesLogo.png"
                alt="logo"
              />
            </Link>
          </div>

          <div className="mt-2 block text-2xl md:text-3xl xl:text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 ">
            Privacy Policy
          </div>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Consent to installation of the App
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            Under data protection laws, we are required to provide you with
            certain information about who we are, how we process your personal
            data and for what purposes, and your rights in relation to your
            personal data. This information is provided in{" "}
            <a
              className="underline"
              href="https://www.urdureaderapp.com/voices/privacy-policy"
            >
              www.urdureaderapp.com/voices/privacy-policy
            </a>{" "}
            and it is important that you read that information.
          </p>

          <p className="text-justify text-sm font-normal leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">YES</span> I
            consent to the installation of the App and I consent to this App
            being updated on my mobile phone automatically with new versions.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            How you can withdraw consent
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Once you provide consent by selecting "YES", you may change your
            mind and withdraw consent at any time by contacting us at{" "}
            <a className="underline" href="mailto:contact@urdureaderapp.com">
              contact@urdureaderapp.com
            </a>{" "}
            but that will not affect the lawfulness of any processing carried
            out before you withdraw your consent.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Location Data
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            FAVOURITE VOICES APP is NOT collecting your Location Data.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            FAVOURITE VOICES APP (we) are committed to protecting your personal
            data and respecting your privacy.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Introduction
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            This policy (together with our end-user licence agreement as set out
            at{" "}
            <a
              className="underline"
              href="https://www.urdureaderapp.com/voices/terms"
            >
              www.urdureaderapp.com/voices/terms
            </a>{" "}
            <span className="font-medium opacity-100 text-black">EULA</span>)
            and any additional terms of use incorporated by reference into the
            EULA, together our{" "}
            <span className="font-medium opacity-100 text-black">
              Terms of Use
            </span>
            ) applies to your use of:
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            FAVOURITE VOICES APP (we) are committed to protecting your personal
            data and respecting your privacy.
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              FAVOURITE VOICES APP mobile application software, once you have
              downloaded or streamed a copy of the App onto your mobile
              telephone or handheld device (
              <span className="font-medium opacity-100 text-black">Device</span>
              ).
            </li>
            <li className="text-sm font-normal text-black ">
              Any of the services accessible through the App (
              <span className="font-medium opacity-100 text-black">
                Services
              </span>
              ) that are available on the App Site or other sites of ours (
              <span className="font-medium opacity-100 text-black">
                Services Sites
              </span>
              ), unless the EULA states that a separate privacy policy applies
              to a particular Service, in which case that privacy policy only
              applies. This policy sets out the basis on which any personal data
              we collect from you, or that you provide to us, will be processed
              by us. This App is not intended for children and we do not
              knowingly collect data relating to children. Please read the
              following carefully to understand our practices regarding your
              personal data and how we will treat it.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            This policy is provided in a layered format so you can click through
            to the specific areas set out below. Alternatively, you can download
            a pdf version of the policy here:{" "}
            <Link className="underline " to="/voices/privacy-policy">
              www.urdureaderapp.com/voices/privacy-policy
            </Link>{" "}
          </p>
        </div>

        {/* glossary terms */}

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Important information and who we are
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            MILESTAND LIMITED is the controller and is responsible for your
            personal data (collectively referred to as "Company", "we", "us" or
            "our" in this policy).
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            If you have any questions about this privacy policy, please contact
            them using the details set out below.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Contact details
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Our full details are:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              Full name of legal entity: MILESTAND LIMITED
            </li>
            <li className="text-sm font-normal text-black ">
              Name or title: Manager
            </li>
            <li className="text-sm font-normal text-black ">
              Email address: contact@urdureaderapp.com
            </li>
            <li className="text-sm font-normal text-black ">
              Postal address: 86 – 90 PAUL STREET, LONDON, EC21 4NE UNITED
              KINGDOM
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (
            <span className="font-medium opacity-100 text-black">ICO</span>),
            the UK regulator for data protection issues.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Changes to the privacy policy and your duty to inform us of changes
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We keep our privacy policy under regular review.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            This version was last updated on 15 JUNE 2022. It may change and if
            it does, these changes will be posted on this page and, where
            appropriate, notified to you by email{" "}
            <span className="font-medium opacity-100 text-black">OR</span> when
            you next start the App or log onto one of the Services Sites. The
            new policy may be displayed on-screen and you may be required to
            read and accept the changes to continue your use of the App or the
            Services
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during our relationship with you.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Third party links
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Our Sites may, from time to time, contain links to and from the
            websites of our partner networks, advertisers and affiliates. Please
            note that these websites and any services that may be accessible
            through them have their own privacy policies and that we do not
            accept any responsibility or liability for these policies or for any
            personal data that may be collected through these websites or
            services, such as Contact Please check these policies before you
            submit any personal data to these websites or use these services.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            The data we collect about you
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We may collect, use, store and transfer different kinds of personal
            data about you as follows:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">Identity Data.</li>
            <li className="text-sm font-normal text-black ">Contact Data.</li>
            <li className="text-sm font-normal text-black ">
              Transaction Data.
            </li>
            <li className="text-sm font-normal text-black ">Device Data.</li>
            <li className="text-sm font-normal text-black ">Content Data.</li>
            <li className="text-sm font-normal text-black ">Profile Data.</li>
            <li className="text-sm font-normal text-black ">Usage Data.</li>
            <li className="text-sm font-normal text-black ">
              Marketing and Communications Data.
            </li>
            <li className="text-sm font-normal text-black ">Location Data</li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health, and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            How is your personal data collected?
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We will collect and process the following data about you:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Information you give us.
              </span>{" "}
              This is information (including Identity, Contact, Financial, and
              Marketing and Communications Data) you consent to giving us about
              you by filling in forms on the App Site and the Services Sites
              (together
              <span className="font-medium opacity-100 text-black">
                Our Sites
              </span>
              ), or by corresponding with us (for example, by email or chat). It
              includes information you provide when you register to use the App
              Site, download or register an App, subscribe to any of our
              Services, search for an App or Service or make an in-App purchase
              and when you report a problem with an App, our Services, or any of
              Our Sites. If you contact us, we will keep a record of that
              correspondence.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Information we collect about you and your device.
              </span>{" "}
              Each time you visit one of Our Sites or use one of our Apps we
              will automatically collect personal data including Device, Content
              and Usage Data. We collect this data using cookies and other
              similar technologies. Please see our cookie policy below for
              further details.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Information we receive from other sources including third
                parties and publicly available sources.
              </span>{" "}
              We will receive personal data about you from various third parties
              as set out below:
            </li>
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black ">
                Device Data from the following parties:
              </li>
              <li className="text-sm font-normal text-black ">
                analytics providers;.
              </li>
              <li className="text-sm font-normal text-black ">
                advertising networks based inside{" "}
                <span className="font-medium opacity-100 text-black">OR</span>{" "}
                outside the UK; and
              </li>
              <li className="text-sm font-normal text-black ">
                search information providers based inside{" "}
                <span className="font-medium opacity-100 text-black">OR</span>{" "}
                outside the UK.
              </li>
              <li className="text-sm font-normal text-black ">
                Contact, Financial and Transaction Data from providers of
                technical, payment and delivery services based inside{" "}
                <span className="font-medium opacity-100 text-black">OR</span>{" "}
                outside the UK;
              </li>
              <li className="text-sm font-normal text-black ">
                Identity and Contact Data from data brokers or aggregators based
                inside{" "}
                <span className="font-medium opacity-100 text-black">OR</span>{" "}
                outside the UK;
              </li>
              <li className="text-sm font-normal text-black ">
                Identity and Contact Data from publicly available sources, and
              </li>
            </ul>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">Cookies</h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We use cookies to distinguish you from other users of the App, App
            Site, the distribution platform (Appstore) or Services Sites and to
            remember your preferences. This helps us to provide you with a good
            experience when you use the App or browse any of Our Sites and also
            allows us to improve the App and Our Sites. For detailed information
            on the cookies we use, the purposes for which we use them and how
            you can exercise your choices regarding our use of your cookies, see
            our cookie policy.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            How we use your personal data
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We will only use your personal data when the law allows us to do so.
            Most commonly we will use your personal data in the following
            circumstances:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              Where you have consented before the processing.
            </li>
            <li className="text-sm font-normal text-black ">
              Where we need to perform a contract, we are about to enter or have
              entered with you.
            </li>
            <li className="text-sm font-normal text-black ">
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li className="text-sm font-normal text-black ">
              Where we need to comply with a legal or regulatory obligation.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We will only send you direct marketing communications by email or
            text if we have your consent. You have the right to withdraw that
            consent at any time by contacting us.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We will get your express opt-in consent before we share your
            personal data with any third party for marketing purposes.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Purposes for which we will use your personal data
          </h2>
        </div>

        {/* table starts */}

        <div className="mt-8 mx-5 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-6"
                      >
                        Purpose/activity
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-black"
                      >
                        Type of data
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-black"
                      >
                        Lawful basis for processing
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className=" py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        To install the App and register you as a new App user
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Identity
                          </li>
                          <li className="text-sm font-normal text-black">
                            Contact{" "}
                          </li>
                          <li className="text-sm font-normal text-black">
                            Device
                          </li>
                        </ul>
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        Your consent
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        To process in-App purchases and deliver Services
                        including managing payments and collecting money owed to
                        us
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Identity
                          </li>
                          <li className="text-sm font-normal text-black">
                            Contact{" "}
                          </li>
                          <li className="text-sm font-normal text-black">
                            Transaction
                          </li>
                          <li className="text-sm font-normal text-black">
                            Device
                          </li>
                          <li className="text-sm font-normal text-black">
                            Marketing and Communications
                          </li>
                        </ul>
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Your consent
                          </li>
                          <li className="text-sm font-normal text-black">
                            Performance of a contract with you
                          </li>
                          <li className="text-sm font-normal text-black">
                            Necessary for our legitimate interests (to recover
                            debts due to us)
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        To manage our relationship with you including notifying
                        you of changes to the App or any Services
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Identity
                          </li>
                          <li className="text-sm font-normal text-black">
                            Contact{" "}
                          </li>
                          <li className="text-sm font-normal text-black">
                            Profile
                          </li>
                          <li className="text-sm font-normal text-black">
                            Marketing and Communications
                          </li>
                        </ul>
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Your consent
                          </li>
                          <li className="text-sm font-normal text-black">
                            Performance of a contract with you
                          </li>
                          <li className="text-sm font-normal text-black">
                            Necessary for our legitimate interests (to keep
                            records updated and to analyse how customers use our
                            products/ Services)
                          </li>
                          <li className="text-sm font-normal text-black">
                            Necessary to comply with legal obligations (to
                            inform you of any changes to our terms and
                            conditions)
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        To administer and protect our business and this App
                        including troubleshooting, data analysis and system
                        testing
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Identity
                          </li>
                          <li className="text-sm font-normal text-black">
                            Contact{" "}
                          </li>
                          <li className="text-sm font-normal text-black">
                            Device
                          </li>
                        </ul>
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        Necessary for our legitimate interests (for running our
                        business, provision of administration and IT services,
                        network security)
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            To deliver content and advertisements to you
                          </li>
                          <li className="text-sm font-normal text-black">
                            To make recommendations to you about goods or
                            services which may interest you
                          </li>
                          <li className="text-sm font-normal text-black">
                            To measure and analyse the effectiveness of the
                            advertising we serve you
                          </li>
                          <li className="text-sm font-normal text-black">
                            To monitor trends so we can improve the App
                          </li>
                        </ul>
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Identity
                          </li>
                          <li className="text-sm font-normal text-black">
                            Contact{" "}
                          </li>
                          <li className="text-sm font-normal text-black">
                            Device
                          </li>
                          <li className="text-sm font-normal text-black">
                            Content
                          </li>
                          <li className="text-sm font-normal text-black">
                            Profile
                          </li>
                          <li className="text-sm font-normal text-black">
                            Usage
                          </li>
                          <li className="text-sm font-normal text-black">
                            Marketing and Communications
                          </li>
                        </ul>
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        <ul className="mx-4 list-disc">
                          <li className="text-sm font-normal text-black">
                            Consent
                          </li>
                          <li className="text-sm font-normal text-black">
                            Necessary for our legitimate interests (to develop
                            our products/Services and grow our business)
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Table ends */}

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Disclosures of your personal data
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            When you consent to providing us with your personal data, we will
            also ask you for your consent to share your personal data with the
            third parties set out below for the purposes set out in the table
            Purposes for which we will use your personal data:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              Internal Third Parties as set out in the Glossary.
            </li>
            <li className="text-sm font-normal text-black ">
              External Third Parties as set out in the Glossary.
            </li>
            <li className="text-sm font-normal text-black ">
              Specific third parties.
            </li>
            <li className="text-sm font-normal text-black ">
              Third parties to whom we may choose to sell, transfer or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this privacy policy.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            International transfers
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We do transfer your personal data outside the UK.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We ensure your personal data is protected by requiring all our group
            companies to follow the same rules when processing your personal
            data. These rules are called "binding corporate rules".
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Many of our external third parties are based outside the UK so their
            processing of your personal data will involve a transfer of data
            outside the UK.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Whenever we transfer your personal data out of the UK, we ensure a
            similar degree of protection is afforded to it by ensuring at least
            one of the following safeguards is implemented:
          </p>

          <ul className="mx-6 list-disc">
            <li className="text-sm font-normal text-black ">
              We will only transfer your personal data to countries that have
              been deemed to provide an adequate level of protection for
              personal data.
            </li>
            <li className="text-sm font-normal text-black ">
              Where we use certain service providers, we may use specific
              contracts approved by the UK which give personal data the same
              protection it has in the UK.
            </li>
          </ul>

          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Please contact us if you want further information on the specific
            mechanism used by us when transferring your personal data out of the
            UK.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Data security
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Once we have received your information, we will use strict
            procedures and security features to try to prevent your personal
            data from being accidentally lost, used or accessed in an
            unauthorised way.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We will collect and store personal data on your Device using
            application data caches and browser web storage (including HTML5)
            and other technology.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator when we
            are legally required to do so.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Data retention
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            By law we have to keep basic information about our customers
            (including Contact, Identity, Financial and Transaction Data) for
            six years after they cease being customers for tax purposes.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            In some circumstances you can ask us to delete your data: see Your
            legal rights below for further information
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Your legal rights
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Under certain circumstances you have the following rights under data
            protection laws in relation to your personal data
          </p>

          <ul className="mx-6 list-disc">
            <li className="text-sm font-normal text-black italic ">
              Request access to your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Request correction of your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Request erasure of your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Object to processing of your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Request restriction of processing your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Request transfer or your personal data.
            </li>
            <li className="text-sm font-normal text-black italic ">
              Right to withdraw consent.
            </li>
          </ul>

          <p className="text-justify text-sm font-normal leading-tight text-black ">
            You also have the right to ask us not to continue to process your
            personal data for marketing purposes.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            You can exercise any of these rights at any time by contacting us at
            contact@urdureaderapp.com
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Lawful basis
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">Consent</span>{" "}
            means processing your personal data where you have signified your
            agreement by a statement or clear opt-in to processing for a
            specific purpose. Consent will only be valid if it is a freely
            given, specific, informed and unambiguous indication of what you
            want. You can withdraw your consent at any time by contacting us
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">
              Legitimate Interest
            </span>{" "}
            means the interest of our business in conducting and managing our
            business to enable us to give you the best service/product and the
            best and most secure experience. We make sure we consider and
            balance any potential impact on you (both positive and negative) and
            your rights before we process your personal data for our legitimate
            interests. We do not use your personal data for activities where our
            interests are overridden by the impact on you (unless we have your
            consent or are otherwise required or permitted to by law). You can
            obtain further information about how we assess our legitimate
            interests against any potential impact on you in respect of specific
            activities by contacting us.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">
              {" "}
              Performance of Contract
            </span>{" "}
            means processing your data where it is necessary for the performance
            of a contract to which you are a party or to take steps at your
            request before entering into such a contract.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">
              {" "}
              Comply with a legal obligation{" "}
            </span>{" "}
            means processing your personal data where it is necessary for
            compliance with a legal obligation that we are subject to.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Third parties
          </h2>
          <h2 className="text text-base font-medium text-black">
            External third parties
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Service providers acting as processors based in India who provide IT
            and system administration services.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            Professional advisers acting as processors or joint controllers
            including lawyers, bankers, auditors and insurers based in the UK
            who provide consultancy, banking, legal, insurance and accounting
            services.
          </p>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            HM Revenue and Customs, regulators and other authorities acting as
            processors or joint controllers based in the UK who require
            reporting of processing activities in certain circumstances.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Your legal rights
          </h2>
          <p className="text-justify text-sm font-normal leading-tight text-black ">
            You have the right to:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Request access
              </span>{" "}
              to your personal data (commonly known as a "data subject access
              request"). This enables you to receive a copy of the personal data
              we hold about you and to check that we are lawfully processing it.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Information we collect about you and your device.
              </span>{" "}
              Each time you visit one of Our Sites or use one of our Apps we
              will automatically collect personal data including Device, Content
              and Usage Data. We collect this data using cookies and other
              similar technologies. Please see our cookie policy below for
              further details.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Request correction
              </span>{" "}
              of your personal data. This enables you to ask us to delete or
              remove personal data where there is no good reason for us
              continuing to process it. You also have the right to ask us to
              delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Request erasure
              </span>{" "}
              of the personal data that we hold about you. This enables you to
              have any incomplete or inaccurate data we hold about you
              corrected, though we may need to verify the accuracy of the new
              data you provide to us.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Object to processing
              </span>{" "}
              of your personal data where we are relying on a legitimate
              interest (or those of a third party) and there is something about
              your particular situation which makes you want to object to
              processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing
              purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which
              override your rights and freedoms.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Request restriction of processing
              </span>{" "}
              of your personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios:
            </li>
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black ">
                if you want us to establish the data's accuracy;
              </li>
              <li className="text-sm font-normal text-black ">
                where our use of the data is unlawful, but you do not want us to
                erase it;.
              </li>
              <li className="text-sm font-normal text-black ">
                where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims;
                or
              </li>
              <li className="text-sm font-normal text-black ">
                you have objected to our use of your data, but we need to verify
                whether we have overriding legitimate grounds to use it.
              </li>
            </ul>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Request the transfer
              </span>{" "}
              of your personal data to you or to a third party. We will provide
              to you, or a third party you have chosen, your personal data in a
              structured, commonly used, machine-readable format. Note that this
              right only applies to automated information which you initially
              provided consent for us to use or where we used the information to
              perform a contract with you.
            </li>
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Withdraw consent at any time
              </span>{" "}
              where we are relying on consent to process your personal data.
              However, this will not affect the lawfulness of any processing
              carried out before you withdraw your consent. If you withdraw your
              consent, we may not be able to provide certain products or
              services to you. We will advise you if this is the case at the
              time you withdraw your consent.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            Description of categories of personal data
          </h2>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Identity Data
              </span>
              : first name, last name, maiden name, username or similar
              identifier, marital status, title, date of birth, gender.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Contact Data
              </span>
              : billing address, delivery address, email address and telephone
              numbers.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Financial Data
              </span>
              : bank account and payment card details.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Transaction Data
              </span>
              : includes details about payments to and from you and details of
              in-App purchases.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Device Data
              </span>
              : includes the type of mobile device you use, a unique device
              identifier (for example, your Device's IMEI number, the MAC
              address of the Device's wireless network interface, or the mobile
              phone number used by the Device], mobile network information, your
              mobile operating system, the type of mobile browser you use, time
              zone setting.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Content Data
              </span>
              : includes information stored on your Device, including friends'
              lists, login information, photos, videos or other digital content,
              check-ins.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Profile Data
              </span>
              : includes your username and password, in-App purchase history,
              your interests, preferences, feedback and survey responses.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Usage Data
              </span>
              : includes details of your use of any of our Apps or your visits
              to any of Our Sites including, but not limited to, traffic data
              and other communication data, whether this is required for our own
              billing purposes or otherwise and the resources that you access.
            </li>

            <li className="text-sm font-normal text-black ">
              <span className="font-medium opacity-100 text-black">
                Marketing and Communications Data
              </span>
              : includes your preferences in receiving marketing from us and our
              third parties and your communication preferences.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPage;
