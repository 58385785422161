import React from "react";
import { Link } from "@reach/router";

const DisclaimerPage = () => {
  return (
    <div className="relative py-4 font-grotesk bg-light-gray overflow-hidden min-h-screen">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className="font-grotesk relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg mx-auto">
          <div className="flex items-center justify-center flex-shrink-0">
            <Link to="/">
              <img
                className="h-16 md:h-18 xl:h-28 2xl:h-32 w-auto  p-2 rounded-md"
                src="/assets/images/icons/favouriteVoicesLogo.png"
                alt="logo"
              />
            </Link>
          </div>

          <div className="mt-2 block text-2xl md:text-3xl xl:text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900">
            Disclaimer
          </div>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            These terms may have changed since you last reviewed them.
          </h2>
          <h2 className="text text-base font-medium text-black">
            Where to find information about us and our products
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            You can find everything you need to know about us, MILESTAND
            LIMITED, and our products on our APP. We also confirm the key
            information to you in writing before or after you order, either by
            email or in your online account.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-6">
          <h2 className="text text-base font-medium text-black">
            When you buy from us you are agreeing that:
          </h2>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              We might charge you when we accept your order, or we supply your
              product.
            </li>
            <li className="text-sm font-normal text-black ">
              We're not responsible for delays outside our control.
            </li>
            <li className="text-sm font-normal text-black ">
              We can withdraw products.
            </li>
            <li className="text-sm font-normal text-black ">
              We can end our contract with you.
            </li>
            <li className="text-sm font-normal text-black ">
              We use your personal data as set out in our Privacy Notice.
            </li>
            <li className="text-sm font-normal text-black ">
              You have several options for resolving disputes with us.
            </li>
            <li className="text-sm font-normal text-black ">
              Other important terms apply to our contract.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We will charge you when we accept your order, or when we supply this
            App
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            From{" "}
            <span className="text-red-600 font-medium">30 September 2022</span>{" "}
            , after the trial period is over, we will be charging you an annual
            subscription fee of{" "}
            <span className="text-red-600 font-medium">$15</span>. This annual
            subscription fees will increase over time. We will charge this
            subscription fees at the start and provide the service to you over
            the year
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We pass on increases in VAT
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If the rate of VAT changes between your order date and the date we
            supply the product, we adjust the rate of VAT that you pay, unless
            you have already paid in full before the change in the rate of VAT
            takes effect.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We're not responsible for delays outside our control
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If our supply of your product is delayed by an event outside our
            control, we contact you as soon as possible to let you know and do
            what we can to reduce the delay. As long as we do this, we won't
            compensate you for the delay, but if the delay is likely to be
            substantial you can contact our Customer Service Team :{" "}
            <span className="underline">contact@urdureaderapp.com</span> to end
            the contract and receive a refund for any products you have paid for
            in advance, but not received, less reasonable costs we have already
            incurred.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Your legal right to change your mind
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            For most of our products bought online, you have a legal right to
            change your mind about your purchase and receive a refund of what
            you paid for it. This is subject to some conditions, as set out
            below.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Our goodwill guarantees
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            In addition, we, MILESTAND LIMITED offer our UK customers a goodwill
            guarantee for most products bought online, which is more generous
            than your legal rights in the ways set out below. This goodwill
            guarantee does not affect your legal rights if there is something
            wrong with your product.
          </p>
        </div>

        {/* table starts */}
        <div className="mt-8 mx-5 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-6"
                      >
                        Your legal rights
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-black"
                      >
                        How our goodwill guarantee is more generous
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className=" py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        14 days to change your mind, online sales only.
                      </td>
                      <td className=" px-3 py-4 text-sm text-black">
                        14 days to change your mind, online sales only.
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-3 text-sm font-normal text-black sm:pl-6">
                        You pay costs of return
                      </td>
                      <td className="px-3 py-4 text-sm text-black">
                        We pay costs of return
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Table ends */}

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            When you can't change your mind
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            You can't change your mind about an order for:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              digital products, after you have started to download or stream
              these;
            </li>
            <li className="text-sm font-normal text-black ">
              services, once these have been completed;
            </li>
            <li className="text-sm font-normal text-black ">
              products sealed for health protection or hygiene purposes, once
              these have been unsealed after you receive them;
            </li>
            <li className="text-sm font-normal text-black ">
              sealed audio or sealed video recordings or sealed computer
              software, once these products are unsealed after you receive them;
            </li>
            <li className="text-sm font-normal text-black ">
              goods that are made to your specifications or are clearly
              personalised; and
            </li>
            <li className="text-sm font-normal text-black ">
              goods which become mixed inseparably with other items after their
              delivery.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            The deadline for changing your mind.
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If you change your mind about a product you must let us know no
            later than 7 days after:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              the day we deliver your product, if it is goods. If the goods are
              for regular delivery (for example, a subscription), you can only
              change your mind after the first delivery. If the{" "}
              <span className="font-medium opacity-100 text-black">goods</span>{" "}
              are split into several deliveries over different days, the period
              runs from the day after the last delivery.
            </li>
            <li className="text-sm font-normal text-black ">
              the day we confirm we have accepted your order, if it is for a{" "}
              <span className="font-medium opacity-100 text-black">
                service
              </span>
              .
            </li>
            <li className="text-sm font-normal text-black ">
              the day we confirm we have accepted your order, if it is for{" "}
              <span className="font-medium opacity-100 text-black">
                service digital content for download or streaming
              </span>
              , although you can't change your mind about digital content once
              we have started providing it.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            How to let us know
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            To let us know you want to change your mind, contact our Customer
            Service Team:{" "}
            <span className="underline">contact@urdureaderapp.com</span>
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You have to pay for services you received before you change your
            mind.
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If you bought a service, we don't refund you for the time you were
            receiving it before you told us you'd changed your mind.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You have to pay for services you received before you change your
            mind.
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If you bought a service, we don't refund you for the time you were
            receiving it before you told us you'd changed your mind.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            When and how we refund you
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We refund you as soon as possible and within 14 days of you telling
            us you've changed your mind. We refund you by the method you used
            for payment. We don't charge a fee for the refund.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You can end an on-going contract (find out how)
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We tell you when and how you can end an on-going contract with us
            (for example, for regular services or a subscription to digital
            content or goods) during the order process and we confirm this
            information to you in writing after we've accepted your order. If
            you have any questions, please contact our Customer Service Team:{" "}
            <span className="underline">contact@urdureaderapp.com</span>
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You have rights if there is something wrong with your product
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If you think there is something wrong with your product, you must
            contact our Customer Service Team:{" "}
            <span className="underline">contact@urdureaderapp.com</span>. We
            honour our legal duty to provide you with products that are as
            described to you on our website and that meet all the requirements
            imposed by law. Your legal rights are summarised below. These are
            subject to certain exceptions. For detailed information please visit
            the Citizens Advice website{" "}
            <a href="https://www.citizensadvice.org.uk/" className="underline">
              www.citizensadvice.org.uk
            </a>{" "}
            . Remember too that You have several options for resolving disputes
            with us.
          </p>
        </div>

        <div className="border mx-5 my-4 py-1 border-black">
          <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
            <h2 className="text text-base font-medium text-black">
              Summary of your key legal rights
            </h2>
            <p className="text-justify text-sm leading-tight text-black ">
              If your product is{" "}
              <span className="font-medium opacity-100 text-black">goods</span>{" "}
              , the Consumer Rights Act 2015 says goods must be as described,
              fit for purpose and of satisfactory quality. During the expected
              lifespan of your product your legal rights entitle you to the
              following:
            </p>
          </div>

          <div className="flex flex-col items-start justify-start space-y-1 pt-3">
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black ">
                Up to 30 days: if your goods are faulty, then you can get a
                refund.
              </li>
              <li className="text-sm font-normal text-black ">
                Up to six months: if your goods can't be repaired or replaced,
                then you're entitled to a full refund, in most cases
              </li>
              <li className="text-sm font-normal text-black ">
                Up to six years: if your goods do not last a reasonable length
                of time you may be entitled to some money back.
              </li>
            </ul>
          </div>

          <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
            <p className="text-justify text-sm leading-tight text-black ">
              If your product is{" "}
              <span className="font-medium opacity-100 text-black">
                digital content
              </span>{" "}
              , the Consumer Rights Act 2015 says digital content must be as
              described, fit for purpose and of satisfactory quality:
            </p>
          </div>

          <div className="flex flex-col items-start justify-start space-y-1 pt-3">
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black ">
                If your digital content is faulty, you're entitled to a repair
                or a replacement.
              </li>
              <li className="text-sm font-normal text-black ">
                If the fault can't be fixed, or if it hasn't been fixed within a
                reasonable time and without significant inconvenience, you can
                get some or all of your money back.
              </li>
              <li className="text-sm font-normal text-black ">
                If you can show the fault has damaged your device and we haven't
                used reasonable care and skill, you may be entitled to a repair
                or compensation
              </li>
            </ul>
          </div>

          <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
            <p className="text-justify text-sm leading-tight text-black ">
              If your product is{" "}
              <span className="font-medium opacity-100 text-black">
                services
              </span>{" "}
              , the Consumer Rights Act 2015 says:
            </p>
          </div>

          <div className="flex flex-col items-start justify-start space-y-1 pt-3">
            <ul className="mx-10 list-disc">
              <li className="text-sm font-normal text-black ">
                You can ask us to repeat or fix a service if it's not carried
                out with reasonable care and skill or get some money back if we
                can't fix it.
              </li>
              <li className="text-sm font-normal text-black ">
                If a price hasn't been agreed upfront, what you're asked to pay
                must be reasonable.
              </li>
              <li className="text-sm font-normal text-black ">
                If a time hasn't been agreed upfront, it must be carried out
                within a reasonable time.
              </li>
            </ul>
          </div>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We can change products and these terms
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">
              Changes we can always make
            </span>
            . We can always change a product:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              to reflect changes in relevant laws and regulatory requirements
            </li>
            <li className="text-sm font-normal text-black ">
              to make minor technical adjustments and improvements, for example
              to address a security threat. These are changes that don't affect
              your use of the product; and
            </li>
            <li className="text-sm font-normal text-black ">
              to update digital content, provided that the digital content
              always matches the description of it that we provided to you
              before you bought it. We might ask you to install these updates.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We can suspend supply (and you have rights if we do)
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            <span className="font-medium opacity-100 text-black">
              We can suspend the supply of a product
            </span>
            . We do this to:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              deal with technical problems or make minor technical changes;
            </li>
            <li className="text-sm font-normal text-black ">
              update the product to reflect changes in relevant laws and
              regulatory requirements; or
            </li>
            <li className="text-sm font-normal text-black ">
              make changes to the product (see We can change products and these
              terms).
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We let you know, may adjust the price and may allow you to terminate
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We contact you in advance to tell you we're suspending supply,
            unless the problem is urgent or an emergency. If we suspend the
            product for longer than 1 month in any 6-month period, we adjust the
            price, so you don't pay for it while its suspended. If we suspend
            supply, or tell you we're going to suspend supply, for more than 3
            months you can contact our Customer Service Team:{" "}
            <span className="underline">contact@urdureaderapp.com</span> to end
            the contract and we'll refund any sums you've paid in advance for
            products you won't receive. We
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We can withdraw products
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We can stop providing a product, such as an ongoing service or a
            subscription for digital content or goods. We let you know at least
            30 days in advance, and we refund any sums you've paid in advance
            for products which won't be provided.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We can end our contract with you
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We can end our contract with you for a product and claim any
            compensation due to us if:
          </p>
        </div>

        <div className="flex flex-col items-start justify-start space-y-1 pt-3">
          <ul className="mx-10 list-disc">
            <li className="text-sm font-normal text-black ">
              you don't make any payment to us when it's due and you still don't
              make payment within 30 days of our reminding you that payment is
              due;
            </li>
            <li className="text-sm font-normal text-black ">
              you don't, within a reasonable time of us asking for it, provide
              us with information, cooperation or access that we need to provide
              the product.
            </li>
            <li className="text-sm font-normal text-black ">
              you don't, within a reasonable time, either allow us to deliver
              the product to you or collect it from us.
            </li>
          </ul>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We use your personal data as set out in our Privacy Notice
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            How we use any personal data you give us is set out in our Privacy
            Notice:{" "}
            <a
              href="https://www.urdureaderapp.com/voices/privacy-policy/"
              className="underline"
            >
              www.urdureaderapp.com/voices/privacy-policy
            </a>{" "}
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You have several options for resolving disputes with us
          </h2>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Our complaints policy
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            Our Customer Service Team:{" "}
            <span className="underline">contact@urdureaderapp.com</span> will do
            their best to resolve any problems you have with us or our products
            as per our Complaints policy.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Resolving disputes without going to court
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            Alternative dispute resolution is an optional process where an
            independent body considers the facts of a dispute and seeks to
            resolve it, without you having to go to court. You can submit a
            complaint to London Mediation Service through their website at{" "}
            <a href="https://www.tlms.org.uk/" className="underline">
              www.tlms.org.uk
            </a>
            . London Mediation Service does not charge you for making a
            complaint and you're not satisfied with the outcome you can still go
            to court.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You can go to court
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            These terms are governed by English law and wherever you live you
            can bring claims against us in the English courts. We can claim
            against you in the courts of the country you live in
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Other important terms apply to our contract
          </h2>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            We can transfer our contract with you, so that a different
            organisation is responsible for supplying your product
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We'll tell you in writing if this happens and we'll ensure that the
            transfer won't affect your rights under the contract. If you're
            unhappy with the transfer, you can contact our Customer Service
            Team: <span className="underline">contact@urdureaderapp.com</span>{" "}
            to end the contract within 14 days of us telling you about it and we
            will refund you any payments you've made in advance for products not
            provided.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            You can only transfer your contract with us to someone else if we
            agree to this
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            You can transfer our guarantee (as explained in Our goodwill
            guarantee) to a new owner of the product. We can require the new
            owner to prove you transferred the product to them.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Nobody else has any rights under this contract
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            This contract is between you and us. Nobody else can enforce it and
            neither of us will need to ask anybody else to sign-off on ending or
            changing it.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            If a court invalidates some of this contract, the rest of it will
            still apply
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            If a court or other authority decides that some of these terms are
            unlawful, the rest will continue to apply.
          </p>
        </div>

        <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
          <h2 className="text text-base font-medium text-black">
            Even if we delay in enforcing this contract, we can still enforce it
            later
          </h2>
          <p className="text-justify text-sm leading-tight text-black ">
            We might not immediately chase you for not doing something (like
            paying) or for doing something you're not allowed to, but that
            doesn’t mean we can't do it later.
          </p>
        </div>
      </div>
    </div>
  );
};
export default DisclaimerPage;
