import React from "react";
import { Link } from "@reach/router";
const app_links = [
  {
    name: "app store",
    imageSrc: "/assets/images/icons/ios@3x.png",
    link: "https://apps.apple.com/us/app/urdu-reader/id1631135165",
  },
  {
    name: "play store",
    imageSrc: "/assets/images/icons/googlePlay@3x.png",
    link: "https://play.google.com/store/apps/details?id=com.urdureader",
  },
  {
    name: "web app",
    imageSrc: "/assets/images/icons/web@3x.png",
    link: "https://file.urdureaderapp.com/",
  },
];
const LaunchingSoonLandingPage = () => {
  return (
    <>
      <div className="font-grotesk bg-light-gray min-w-screen relative  flex min-h-screen flex-col justify-start md:flex md:flex-row md:items-start md:justify-between">
        <div className="flex flex-1 flex-col justify-start px-4 pt-3 pb-1 sm:px-6 md:justify-center  lg:flex-none lg:px-8 xl:px-20">
          <div className="z-10 w-full  md:max-w-2xl 3xl:max-w-7xl ">
            <div className="lg:flex pt-12 lg:h-[360px] lg:flex-col lg:justify-between xl:h-[420px] 2xl:h-[480px] 3xl:h-[560px] 4xl:h-[680px]">
              <img
                className="h-14 w-auto lg:h-20 2xl:w-24 2xl:h-24 lg:w-20 3xl:w-32 3xl:h-32 4xl:h-36 4xl:w-36 3xl:mt-12"
                src="/assets/images/icons/udruReaderLogo.png"
                alt="logo"
              />
              <div>
                <h2 className="mt-2 xxxs:mt-3 md:mt-6 lg:mt-1 2xl:mt-6 text-3xl font-bold uppercase tracking-tight text-black lg:text-4xl 2xl:text-5xl 3xl:mt-12 3xl:text-6xl 4xl:text-7xl">
                  urdu reader
                </h2>
              </div>
              <div className="hidden lg:block "></div>
              <div>
                <h2 className="mt-4 md:mt-24 lg:mt-1 2xl:mt-18 text-3xl font-bold capitalize tracking-tight text-black  2xl:text-5xl lg:text-4xl 3xl:mt-12 3xl:text-6xl 4xl:text-7xl">
                  In stores now
                </h2>
                <p className="text-left text-base font-normal leading-tight tracking-tight text-[#555555] md:mt-3 md:leading-none lg:text-xl 2xl:text-2xl 3xl:text-3xl 3xl:w-auto ">
                  Urdu Reader helps you to smartly convert your Urdu documents
                  in to speech.
                </p>
              </div>
            </div>
            <div className="z-50 w-full  mt-10 grid grid-cols-3 gap-1 lg:gap-4  lg:grid-cols-3">
              {app_links.map((app_link) => (
                <div key={app_link.name} className="sm:flex lg:block">
                  <a
                    href={app_link.link}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <img src={app_link.imageSrc} alt={app_link.name} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" hidden md:block object-contain z-0 w-auto lg:z-0 sm:object-cover aspect-w-3 aspect-h-2  sm:aspect-w-5 lg:aspect-none lg:absolute lg:w-auto md:right-0 md:top-0 lg:h-full ">
          <img
            src="/assets/images/background-images/desktop-lg.png"
            alt="mobile"
            className="h-full z-0 w-full object-center object-cover lg:h-full lg:w-full"
          />
        </div>
        {/* mobile */}
        <div className="z-0 my-0.5 flex  flex-row items-center justify-end md:hidden xxs:h-1/4  ">
          <img
            className="h-auto  object-cover"
            src="/assets/images/background-images/mobile-sm.png"
            alt="mobile background"
          />
        </div>

        <div className=" bg-light-gray w-full md:absolute md:bottom-0 md:w-auto md:py-2 md:px-4 lg:px-4 xl:px-16">
          <div className="bg-light-gray absolute bottom-0 w-full px-4  text-left text-sm font-medium text-black sm:relative md:px-0 lg:px-4 3xl:text-xl">
            <div className="flex flex-col md:flex-row">
              <span>&copy; 2022-2023 Urdu Reader. All rights reserved</span>
              <div>
                <Link
                  className="md:ml-3 md:border-l border-gray-500 pl-1 md:pl-3  hover:text-neutral-600"
                  to="/terms"
                >
                  Terms
                </Link>
                <Link
                  className="hover:text-neutral-600 ml-3 border-l border-gray-500  pl-3"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
                <Link
                  className="hover:text-neutral-600 ml-3 border-l border-gray-500  pl-3"
                  to="/disclaimer"
                >
                  Disclaimer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LaunchingSoonLandingPage;
